.snapshot_wrapper {
    background-image: url("../../assets/background2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--componentsPadding);
}

.title_icon_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.icon_wrapper {
    max-height: 65vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.component_icon {
    max-height: 100%;
    max-width: 70%;
    object-fit: contain;
}

.share_icon {
    width: var(--titleIconWidth);
    cursor: pointer;
}

.safety_logo {
    width: clamp(80px, 10vw, 150px);
}

.snapshot_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-self: flex-end;
    margin-top: auto;
}

.title_text_wrapper {
    text-align: center;
}

.component_title {
    font-size: var(--primaryTitleFontSize);
    font-weight: var(--primaryFontWeight);
    color: var(--petrol);
}

.component_order_number {
    font-size: var(--secondaryTitleFontSize);
    font-weight: var(--secondaryFontWeight);
}

@media only screen and (max-height: 576px) {
    .icon_wrapper {
        max-height: 40vh;
    }
}
