.info_button_wrapper {
    position: fixed;
    bottom: clamp(10px, 2vh, 20px);
    right: 7%;
}

.info_button {
    width: var(--titleIconWidth);
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .info_button_wrapper {
        right: 15%;
    }
}
