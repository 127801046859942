.language_dropdown_wrapper .btn,
.language_dropdown_wrapper .btn:focus,
.language_dropdown_wrapper .btn:hover,
.language_dropdown_wrapper .btn:active {
    background-color: var(--grey) !important;
    border: none;
    font-size: var(--primaryTextFontSize);
    font-weight: var(--secondaryFontWeight);
    border-radius: 50px;
}

.language_select_items {
    font-size: var(--primaryTextFontSize);
}

.language_select_items:active {
    background-color: var(--grey) !important;
    color: rgb(0, 0, 0) !important;
}

.language_select_items:hover {
    background-color: var(--grey) !important;
    color: white !important;
}

// to change the gap between text and arrow
.custom-toggle {
    &:after {
        content: "";
        display: inline-block;
        margin-left: clamp(12px, 1.5vw, 22px) !important;
        font-size: clamp(15px, 2vw, 25px);
        vertical-align: middle !important;
        padding-bottom: clamp(2px, 0.5vw, 5px);
    }
}
