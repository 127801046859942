.doc_link {
    display: inline-block;
    width: 100%;
    color: white;
    word-break: break-all;
    font-size: var(--primaryTextFontSize);
    text-decoration: none;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    &:hover {
        color: var(--petrol);
        text-decoration: underline;
        background-color: var(--secondaryDarkGrey);
        &:nth-child(even) {
            background-color: var(--secondaryDarkGrey);
        }
    }
    &:nth-child(even) {
        background-color: var(--grey);
    }
}
