.contact_wrapper {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: var(--componentsPadding);
}

.contact_title {
    color: var(--petrol);
    font-weight: var(--secondaryFontWeight);
    font-size: var(--primaryTitleFontSize);
    margin-bottom: 10%;
}

.contact_text {
    font-size: var(--primaryTextFontSize);
}

.mail_text {
    cursor: pointer;
}

.contact_text:nth-child(2) {
    font-weight: var(--secondaryFontWeight);
}

.contact_icon_wrapper {
    width: auto;
    background-color: var(--petrol);
    border-radius: 50%;
    padding: clamp(2px, 1vw, 7px) 7px;
}
.contact_icon {
    background-color: var(--petrol);
    border-radius: 50%;
    font-size: var(--iconFontSize);
}
