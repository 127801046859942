.general_title {
    color: black;
    background-color: var(--petrol);
    padding: 1%;
    font-size: var(--primaryTitleFontSize);
    font-weight: var(--secondaryFontWeight);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1%;
}

.item_wrapper {
    display: flex;
    flex-direction: row;
    font-size: var(--primaryTextFontSize);
}

.general_item_container {
    flex: 1;
}

.general_item_key {
    padding-left: var(--titleIconWidth);
}

.key_value_wrapper {
    padding: var(--componentsPadding);
    background-color: white;
    color: black;
}
