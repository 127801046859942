.certificate_container {
    display: flex;
    flex-direction: row;
    gap: 3vh 8vw;
    flex-wrap: wrap;
}

.self_certificate_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.certificate_image {
    width: clamp(70px, 10vw, 140px);
    margin: auto;
}

.certificate_text {
    width: fit-content;
    text-align: center;
    font-size: var(--primaryTextFontSize);
    margin-top: 5% !important;
}
