.footer_wrapper {
    background-color: var(--darkGrey);
    padding: var(--componentsPadding);
}

.links_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 5%;
}

.footer_link {
    color: white;
    font-size: var(--primaryTextFontSize);
    text-decoration: none;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    &:hover {
        color: var(--petrol);
        text-decoration: underline;
    }
}

.footer_logo_text_wrapper {
    padding-top: clamp(10px, 5%, 50px);
}

.footer_logo {
    width: clamp(150px, 15vw, 200px);
}

.logo_text {
    font-size: var(--secondaryTextFontSize);
}
