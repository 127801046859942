:root {
    --petrol: #009a93;
    --darkGrey: #333333;
    --secondaryDarkGrey: #4d4d4d;
    --lightGrey: #e5e5e5;
    --grey: #706f6f;

    --titleIconWidth: clamp(25px, 5vw, 50px);
    --componentsPadding: clamp(10px, 2vh, 20px) 15%;
    --iconFontSize: clamp(15px, 3vw, 32px);

    --primaryFontWeight: 700;
    --secondaryFontWeight: 600;
    --thirdaryFontWeight: 500;

    --primaryTitleFontSize: clamp(17px, 2vw, 28px);
    --secondaryTitleFontSize: clamp(15px, 1.6vw, 25px);

    --primaryTextFontSize: clamp(12px, 1.2vw, 20px);
    --secondaryTextFontSize: clamp(7px, 1vw, 9px);

    --sectionTitleIconPadding: clamp(5px, 1vw, 15px);
}

@media only screen and (max-width: 1200px) {
    :root {
        --componentsPadding: clamp(10px, 2vh, 20px) 5%;
    }
}
